html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
}

.wrapper {
  min-height: 100%;
  background-color: black;
  padding-inline: 30px;
}

.header {
  position: sticky;
  top: 0;
  text-align: center;
  /* background-color: lightgray; */
  padding-top: 20px;
  display: flex;
  background-color: black;
  padding-bottom: 10px;
}

.footer_message {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: rgb(133, 95, 95); */
  padding-bottom: 30px;
  padding-top: 10px;
  text-align: center;
  display: flex;
  background-color: black;
}

.back_btn {
  display: flex;
  height: 30px;
  width: 30px;
}

.icon {
  height: 100%;
  width: auto;
}

.title {
  color: white;
  margin-block: 0;
  width: 90%;
  font-size: xx-large;
}

.text_input_message {
  width: 90%;
  height: 25px;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  font-size: large;
}

.chat_button {
  height: 40px;
  margin: auto 10px;
}

.chat_button_img {
  height: 100%;
}

.chat_section {
  height: 80%;
  width: 100%;
}

.message_title {
  width: 90%;
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}

.question_container {
  display: flex;
  color: white;
  flex-direction: column;
  height: 100%;
  /* border: 2px solid white; */
  padding-inline: 20px;
  margin-top: 20px;
  /* gap: 10px; */
}

.question_section {
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 20px;
  /* background-color: aqua; */
  /* margin: 10px; */
}

.question_text {
  flex: 50%;
  text-align: center;
  font-size: 30px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 10px 20px;
}

.or_text{
    color: white;

  }

@media (max-width: 767px) {
  .chat_section {
    gap: 2px;
  }

  h3{
    margin-block: 10px;
  }


  .text_input_message {
    width: 65%;
    padding: 15px 10px;
  }

  .back_btn {
    display: flex;
    height: 20px;
    width: 20px;
  }

  .chat_button {
    margin: auto 5px;
  }

  .message_title {
    font-size: 25px;
  }

  .question_container {
    display: flex;
    color: white;
    flex-direction: column;
    height: 100%;
    padding-inline: 5px;
    margin-top: 10px;
    gap: 0px;
    width: 90%;
  }

  .question_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  .title {
    font-size: larger;
  }

  .question_text {
    flex: 100%;
    width: 100%;
    font-size: 20px;
    border: 2px solid white;
    border-radius: 20px;
    margin-inline: auto;
  }

  .or_text{
    margin-block: 10px;

  }
}
