* {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS */
.container {
  display: flex;
  flex-direction: column;
  background-color: black;
  margin-inline: auto;
  padding-top: 20px;
  height: 100vh;
  gap: 10px;
  color: white;
  align-items: center;
  margin-inline: auto;
  padding-inline: 10px;
  justify-content: center;
}

.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 28px;
  font-weight: 500;
  height: 70px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 6px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.button-17:hover {
  background: #f6f9fe;
  color: #000000;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.logo_container {
  height: 30%;
}

.logo {
  height: 100%;
}

.button_section {
  height: 25%;
}

.button-white {
  background-color: white;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 25%; */
  justify-content: center;
  margin-inline: auto;
  padding-inline: 100px;
  border-radius: 12px;
  gap: 5%;
}

.button_text {
  font-size: x-large;
  font-weight: bold;
}

.button_icon {
  height: 50%;
  /* width: fit-content; */
}

.button-16 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  margin: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.button-16:hover {
  background: #f6f9fe;
  color: #000000;
}

.button-16:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-16:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-16:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-16:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-16:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-16:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-16:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.span {
  border: 2px solid white;
  width: fit-content;
  margin-inline: auto;
  padding: 4px;
  border-radius: 10px;
}

.text-message {
  display: flex;
  font-size: 72px;
  text-shadow: 3px 6px 14px #ffdb00d6;
  align-self: center;
  height: 40%;
  align-items: center;
  justify-content: center;
}

.className {
  position: fixed;
  top: 3%;
  background-color: #f6f9fe9e;
  color: black;
  width: 100%;
}

label {
  font-weight: 600;
  font-size: larger;
}

.stopwatch-time {
  font-size: xx-large;
  font-weight: 500;
}
.footer {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;
}

.black {
  background-color: white;
  color: black;
  border: 2px solid white;
  border-radius: 5px;
  padding: 2px 10px;
}

.message_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 0vh;
  width: 95%;
}

.chat_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  height: 90%;
}

.chat {
  background-color: white;
  color: black;
  width: 90%;
  padding: 10px 20px;
  text-align: left;
  border-radius: 10px;
}

.text_input_section {
  display: flex;
  justify-content: space-around;
  width: 90%;
}

.text_input {
  width: 85%;
  padding: 10px 5px;
  border-radius: 10px;
}

.chat_button {
  background-color: black;
  height: 50px;
  width: 50px;
  border: none;
}

.chat_button_icon {
  /* background-color: black; */
  height: 100px;
  width: 100px;
  border: none;
}

.chat_button_img {
  height: 100%;
  width: 100%;
}

.hover_red {
  height: 90%;
  width: 90%;
  padding-left: 10px;
  border-radius: 50%;
  padding-block: 5px;
}

.red {
  background-color: red;
  border-radius: 50%;
}

label {
  color: white;
}

.language_dropdown {
  margin-top: 15px;
  font-size: large;
}

.student_message {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_button_container {
  height: 70px;
  width: 70px;
  position: fixed;
  bottom: 150px;
  right: 150px;
}

/* .chat_button{
  width: 100%;
  height: 100%;
} */

@media (max-width: 767px) {
  .text_input_section {
    width: 100%;
    gap: 5px;
  }

  .chat_button_container {
    height: 70px;
    width: 70px;
    position: fixed;
    bottom: 110px;
    right: 50px;
  }

  .text-message {
    font-size: 50px;
  }
}
